import {QueryClient} from "@tanstack/react-query";
import {LoaderFunctionArgs, useParams} from "react-router-dom";
import {useBrand} from "@/app/main-provider";
import React, {useState} from "react";
import {Range} from "react-date-range";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {AssemblyCard} from "@/features/assembly/components/assembly-card";
import {AssemblyChart} from "@/features/assembly/components/assembly-chart";
import {AssemblyAdvertisingChart} from "@/features/assembly/components/assembly-advertising-chart";
import {AssemblyFunnel} from "@/features/assembly/components/assembly-funnel";
import {AssemblyOrganicBar} from "@/features/assembly/components/assembly-organic-bar";
import {AssemblyRepeatCustomer} from "@/features/assembly/components/assembly-repeat-customer";
import {getAssemblyQueryOptions} from "@/features/assembly/api/get-assembly";
import {getAssemblyChartQueryOptions} from "@/features/assembly/api/get-assembly-chart";
import {getAssemblyAdvertisingChartQueryOptions} from "@/features/assembly/api/get-assembly-advertising-chart";
import {getAssemblyFunnelQueryOptions} from "@/features/assembly/api/get-assembly-funnel";
import {getAssemblyOrganicBarQueryOptions} from "@/features/assembly/api/get-assembly-organic-bar";
import {getAssemblyRepeatCustomerQueryOptions} from "@/features/assembly/api/get-assembly-repeat-customer";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";
import {AssemblyInfoCard} from "@/features/assembly/components/assembly-info";

export const assemblyDashboardLoader = (queryClient: QueryClient, brand: string) => async ({ params }: LoaderFunctionArgs) => {
    const assemblyId = params.assemblyId || "";

    if(!brand || !assemblyId) return null;

    const assemblyCardQuery = getAssemblyQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const assemblyChartQuery = getAssemblyChartQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const assemblyAdvertisingChartQuery = getAssemblyAdvertisingChartQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const assemblyFunnelQuery = getAssemblyFunnelQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const assemblyOrganicBarQuery = getAssemblyOrganicBarQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const assemblyRepeatCustomerQuery = getAssemblyRepeatCustomerQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const promises = [
        queryClient.getQueryData(assemblyCardQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyCardQuery)),
        queryClient.getQueryData(assemblyChartQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyChartQuery)),
        queryClient.getQueryData(assemblyAdvertisingChartQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyAdvertisingChartQuery)),
        queryClient.getQueryData(assemblyFunnelQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyFunnelQuery)),
        queryClient.getQueryData(assemblyOrganicBarQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyOrganicBarQuery)),
        queryClient.getQueryData(assemblyRepeatCustomerQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyRepeatCustomerQuery)),
    ] as const;

    const [ assemblyCard, assemblyChart, assemblyAdvertisingChart, assemblyFunnel, assemblyOrganicBar, assemblyRepeatCustomer ] = await Promise.all(promises);

    return {
        assemblyCard,
        assemblyChart,
        assemblyAdvertisingChart,
        assemblyFunnel,
        assemblyOrganicBar,
        assemblyRepeatCustomer,
    };
}

export const AssemblyDashboardRoute = () => {
    const { assemblyId = "" } = useParams();
    const { brand } = useBrand();
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>{assemblyId}</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <div className={"grid grid-cols-6 gap-x-5"}>
                <div className={"col-span-3 h-full"}>
                    <AssemblyInfoCard assembly_item={assemblyId}/>
                </div>
                <div className={"col-span-3 h-full"}>
                    <AssemblyCard assembly_item={assemblyId}/>
                </div>
            </div>
            <AssemblyChart
                assembly_item={assemblyId}
                dateRange={dateRange}
            />
            <div className={"grid grid-cols-3 gap-x-5"}>
                <div className={"col-span-2"}>
                    <AssemblyAdvertisingChart
                        assembly_item={assemblyId}
                        dateRange={dateRange}
                    />
                </div>
                <AssemblyFunnel
                    assembly_item={assemblyId}
                    dateRange={dateRange}
                />
            </div>
            <div className={"grid grid-cols-3 gap-x-5"}>
                <div className={"col-span-2"}>
                    <AssemblyOrganicBar
                        assembly_item={assemblyId}
                    />
                </div>
                <AssemblyRepeatCustomer
                    assembly_item={assemblyId}
                />
            </div>
        </>
    );
}