import * as React from "react";

import Echarts, {
    CategoryTooltipFormatter,
    EchartProps,
} from "@/components/ui/chart/echarts";
import {echartColors, echartColorsProducer} from "@/styles/echart-styles";

export type CategoryGraphData = [string, number]

type DonutPieProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    labels: string[];
    series: CategoryGraphData[];
    width?: string;
    height: string;
    colors?: string[];
    center?: string[];
}

const DonutChart = React.forwardRef<HTMLDivElement, DonutPieProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            width,
            height,
            colors = echartColorsProducer,
            center = ['50%', '50%'],
            ...props
        },
        ref
    ) => {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const hasData = series.some(serie => serie && serie.length > 0);

        const pie_graph: EchartProps["option"] = {
            color: colors,
            animation: false,
            title: {
                text: title,
                left: "20",
                top: "15",
                textStyle: {
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    fontSize: 14,
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: (params) => CategoryTooltipFormatter({params})
            },
            legend: {
                orient: 'vertical',
                data: hasData ? labels : [],
                right: "15",
                top: "center",
                borderRadius: 4,
                itemWidth: 16,
                itemHeight: 16,
                textStyle: {
                    color: isDarkMode ? echartColors.white : echartColors.black,
                    fontWeight: "bold",
                }
            },
            series: hasData ? [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'], // Inner and outer radius
                    center: center, // Position of the pie chart
                    avoidLabelOverlap: false,
                    padAngle: 5,
                    minAngle: 5,
                    itemStyle: {
                        borderRadius: 10, // Makes the edges rounded
                    },
                    label: {
                        show: false, // Hides the labels inside the pie chart
                        position: "center"
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '18',
                            fontWeight: 'bold',
                            color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                        },
                    },
                    labelLine: {
                        show: false, // Hides the lines connecting labels
                    },
                    data: series.map((serie, index) => {
                        return { name: serie[0], value: serie[1] };
                    })
                }
            ] : [],
            graphic: hasData ? [] : [{
                type: 'text',
                left: 'center',
                top: 'middle',
                style: { text: "No data available", fontSize: 20, fill: "#999" },
            }],
        }

        return (
            <Echarts
                option={pie_graph}
                width={width}
                height={height}
            />
        );
    }
);

export {
    DonutChart
};