import {useComponent} from "@/features/component/api/get-component";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import SimpleCard, {CardVisMapperProps, CardVisMeta} from "@/components/ui/card/simple-card/simple-card";

export const ComponentCard = ({
    component_item,
}: {
    component_item: string;
}) => {
    const { brand } = useBrand();

    const componentCardQuery = useComponent({
        brand: brand,
        component_item: component_item
    });

    if (componentCardQuery.isLoading) {
        return <div>Component Card Loading...</div>;
    }

    if (!componentCardQuery.data) return null;

    const cardVisMapper: CardVisMapperProps = {
        "Sales": CardVisMeta.Money,
        "Unit Orders": CardVisMeta.Number,
    }

    return (
        <SimpleCard
            data={componentCardQuery.data}
            cardVisMapper={cardVisMapper}
        >
        </SimpleCard>
    );
}