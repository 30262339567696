import {useBrand} from "@/app/main-provider";
import {useAssemblyInfo} from "@/features/assembly/api/get-assembly-info";
import {Card} from "@/components/ui/card/card";
import {Link} from "react-router-dom";
import {currencyF, numberF} from "@/utils/formatter";

export const AssemblyInfoCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyInfoQuery = useAssemblyInfo({
        brand,
        assembly_item,
    });

    if (assemblyInfoQuery.isLoading) {
        return <div>Assembly Info Card Loading...</div>;
    }

    if (!assemblyInfoQuery.data) return null;

    const info = assemblyInfoQuery.data;

    return (
        <a href={info.link} target="_blank" rel="noopener noreferrer">
            <Card className={"flex flex-row gap-3 h-full text-white font-bold"}>
                <div className={"flex flex-col text-sm"}>
                    <div className={"mb-2"}>
                        <img src={info.img} alt={"listing image"}
                             className={"w-[8.78rem] h-[6rem] object-scale-down shadow-2"}/>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-sm text-rigleDarkGrey font-bold"}>Category Rank</span>
                        <span>{info.category}</span>
                        <span>{numberF(info.category_rank)}</span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-sm text-rigleDarkGrey font-bold"}>Subcategory Rank</span>
                        <span>{info.subcategory}</span>
                        <span>{numberF(info.subcategory_rank)}</span>
                    </div>
                </div>

                <div className={"flex flex-col"}>
                    <div className={"flex flex-col"}>
                        <span className={"text-sm text-rigleDarkGrey font-bold"}>Listing Title</span>
                        <span>{info.display_name}</span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-sm text-rigleDarkGrey font-bold"}>Vendor Name</span>
                        <span>{info.vendor_name}</span>
                    </div>
                    <div className={"flex flex-row gap-3"}>
                        <div className={"flex flex-col"}>
                            <span className={"text-sm text-rigleDarkGrey font-bold"}>Buy Box Price</span>
                            <span className={"text-end"}>{currencyF(info.bb_price)}</span>
                        </div>
                        <div className={"flex flex-col"}>
                            <span className={"text-sm text-rigleDarkGrey font-bold"}>Average Price</span>
                            <span className={"text-end"}>{currencyF(info.avg_price)}</span>
                        </div>
                        <div className={"flex flex-col"}>
                            <span className={"text-sm text-rigleDarkGrey font-bold"}>MAP Price</span>
                            <span className={"text-end"}>{currencyF(info.map_price)}</span>
                        </div>
                    </div>
                    <div className={"flex flex-row gap-3"}>
                        <div className={"flex flex-col"}>
                            <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Total</span>
                            <span className={"text-end"}>{numberF(info.inventory_total)}</span>
                        </div>
                        <div className={"flex flex-col"}>
                            <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Inbound</span>
                            <span className={"text-end"}>{numberF(info.inventory_inbound)}</span>
                        </div>
                    </div>
                </div>
            </Card>
        </a>
    );
}