import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";
import {Card, CardHeader, CardBody} from "../card";
import {CardResponse, DataEntry} from "@/types";
import {Range} from "react-date-range";
import TrendPositive from "@/assets/icons/card/trend-positive.svg";
import TrendNegative from "@/assets/icons/card/trend-negative.svg";
import {currencyF, numberF, percentF} from "@/utils/formatter";

const fieldSizeClasses = {
    extraSmall: {
        title: "text-xs",
        period: "text-[0.625rem]",
        subtitle: "text-xs",
        value: "text-xl",
        trend: "text-[0.625rem]",
    },
    small: {
        title: "text-sm",
        period: "text-xs",
        subtitle: "text-sm",
        value: "text-2xl",
        trend: "text-xs",
    },
    medium: {
        title: "text-base",
        period: "text-sm",
        subtitle: "text-base",
        value: "text-3xl",
        trend: "text-sm",
    },
    large: {
        title: "text-lg",
        period: "text-base",
        subtitle: "text-lg",
        value: "text-4xl",
        trend: "text-base",
    },
}

const simpleCardVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "dark",
            },
            size: {
                extraSmall: '',
                small: '',
                medium: '',
                large: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'medium',
        },
    },
);

export type CardVisMapperProps = {
    [key: string]: CardVisMeta
}

export type OmitSubtitleProps = {
    [key: string]: boolean
}

export type CardProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof simpleCardVariants> & {
    data: CardResponse;
    dateRange?: Range[];
    cardVisMapper?: CardVisMapperProps;
    omitSubtitleMapper?: OmitSubtitleProps;
};

export enum CardVisMeta {
    Money,
    Number,
    Percent,
}

const SimpleCard = React.forwardRef<HTMLDivElement, CardProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            dateRange,
            cardVisMapper,
            omitSubtitleMapper,
            ...props
        },
        ref,
    ) => {
        const valueFormatter = (item: DataEntry): string => {
            if (cardVisMapper == undefined) {
                return item.value + "" ?? ""
            }

            if (cardVisMapper[item.name] === CardVisMeta.Money) {
                return currencyF(item.value as number)
            }

            if (cardVisMapper[item.name] === CardVisMeta.Number) {
                return numberF(item.value as number)
            }

            if (cardVisMapper[item.name] === CardVisMeta.Percent) {
                return percentF(item.value as number)
            }

            return item.value + "" ?? ""
        }

        const trendFormatter = (item: DataEntry) => {
            if (!item.value_last || !item.period_last || item.value_last === 0) {
                return "";
            }
            const percent = (item.value as number) / item.value_last - 1

            return (
                <div className={"flex justify-between w-full"}>
                    <span className={"inline-flex"}>
                        {
                            percent > 0 ?
                                <img src={TrendPositive} alt={"Trend Positive"} />
                                :
                                <img src={TrendNegative} alt={"Trend Negative"} />
                        }
                        {" " + percentF(Math.abs(percent))}
                    </span>
                    <span>{ item.period_last }</span>
                </div>
            );
        }

        const subtitleFormatter = (item: DataEntry) => {
            if (omitSubtitleMapper == undefined) {
                return item.name;
            }

            if (omitSubtitleMapper[item.name]) {
                return "";
            }

            return item.name;
        }

        const fieldClasses = fieldSizeClasses[size ?? "medium"];

        return (
            <div className={"flex gap-x-5 h-full"}>
                {data.summary.map((summary, summary_i) => (
                    <Card key={summary_i} className={cn(simpleCardVariants({ variant, size, className }))}>
                        <CardHeader>
                            <span className={fieldClasses.title}>{summary.subtitle}</span>
                            <span className={fieldClasses.period}>{summary.period}</span>
                        </CardHeader>
                        {summary.data.map((item, index) => (
                            <CardBody key={index}>
                                <span className={`text-gray-500 dark:text-white ${fieldClasses.subtitle} mr-2`}>{subtitleFormatter(item)}</span>
                                <span className={`font-bold ${fieldClasses.value} dark:text-white`}>{valueFormatter(item)}</span>
                                <span className={`flex gap-x-1 ${fieldClasses.trend}`}>{trendFormatter(item)}</span>
                            </CardBody>
                        ))}
                    </Card>
                ))}
            </div>
        )
    },
);

export default SimpleCard;