import * as React from "react";

import {CategoryGraphData} from "@/components/ui/chart/pie/pie";
import {echartColors, echartColorsProducer} from "@/styles/echart-styles";
import Echarts, {EchartProps} from "@/components/ui/chart/echarts";
import {numberF} from "@/utils/formatter";

type FunnelProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    series: CategoryGraphData[];
    width?: string;
    height: string;
    colors?: string[];
}

const FunnelChart = React.forwardRef<HTMLDivElement, FunnelProps>(
    (
        {
            className,
            children,
            title,
            series,
            width,
            height,
            colors = echartColorsProducer,
            ...props
        },
        ref
    ) => {
        series = series.sort((a, b) => - a[1] + b[1]);
        const labels = series.map(serie => serie[0]);

        const logTransform = (value: number) => {
            return value == 0 ? 0 : Math.log10(value);
        };

        // Apply log transformation to series values
        const scaledSeries: CategoryGraphData[] = series.map(([label, value]) => [label, logTransform(value)]);

        const adjustedMaxValue = Math.max(...scaledSeries.map(serie => serie[1]));  // Max value after scaling
        const adjustedMinValue = Math.min(...scaledSeries.map(serie => serie[1]));  // Min value after scaling

        const isDarkMode = document.documentElement.classList.contains('dark');
        const hasData = series.some(serie => serie && serie.length > 0);

        const funnel_graph: EchartProps["option"] = {
            color: colors,
            animation: false,
            grid: {
                right: "20%",
                left: "15%",
            },
            title: {
                text: title,
                left: "20",
                top: "15",
                textStyle: {
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    fontSize: 14,
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: function(params: any) {
                    const originalValue = series.find(([label]) => label === params.name)?.[1];
                    return `${params.name}: ${originalValue ? numberF(originalValue) : ""}`;
                }
            },
            legend: hasData ? {
                orient: 'vertical',
                right: '5%',
                bottom: 'center',
                data: labels,
                borderRadius: 4,
                itemWidth: 16,
                itemHeight: 16,
                textStyle: {
                    color: isDarkMode ? echartColors.white : echartColors.black,
                    fontWeight: "bold",
                }
            }: {
                show: false,
            },
            series: [
                {
                    name: 'Funnel',
                    type: 'funnel',
                    left: '10%',   // Position the funnel aligned to the left
                    top: 60,
                    bottom: 60,
                    width: '70%',
                    min: adjustedMinValue,
                    max: adjustedMaxValue,
                    minSize: '10%',    // Min size to keep the rectangular shape
                    maxSize: '100%',
                    sort: 'descending',
                    gap: 10,
                    funnelAlign: 'left',  // Aligns the funnel to the left
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: function(params) {
                            const originalValue = series.find(([label]) => label === params.name)?.[1];
                            return `${params.name} ${originalValue ? numberF(originalValue) : ""}`; // Display original value
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '18',
                            fontWeight: 'bold',
                        },
                    },
                    labelLine: {
                        length: 10,
                        lineStyle: {
                            width: 1,
                            type: 'solid'
                        }
                    },
                    itemStyle: {
                        borderWidth: 0,
                    },
                    data: scaledSeries.map((serie, index) => {
                        return { name: serie[0], value: serie[1] };
                    })
                }
            ],
            graphic: hasData ? [] : [{
                type: 'text',
                left: 'center',
                top: 'middle',
                style: { text: "No data available", fontSize: 20, fill: "#999" },
            }],
        }

        return (
            <Echarts
                option={funnel_graph}
                width={width}
                height={height}
            />
        );
    }
);

export {
    FunnelChart,
};